import React, { Component } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Topbar from "../components/TopbarGarnet";
import FooterGarnet from "../components/FooterGarnet";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { connect } from "react-redux";

const fondoImagenUrl = require("../images/productos_apple.png");
const iconoCentral = require("../images/icono_central.png");

const styles = theme => ({
    tituloPrincipal1: {
        fontFamily: "Source Sans Pro",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "48px",
        lineHeight: "53px",
        color: "#0087C2",
        marginBottom: "1rem"
    },
    tituloPrincipal2: {
        fontFamily: "Source Sans Pro",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "32px",
        lineHeight: "53px",
        color: "#0087C2",
        marginBottom: "3rem"
    },
    textoDetalle: {
        fontFamily: "Source Sans Pro",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "24px",
        lineHeight: "26px",
        color: "#474747",
        marginBottom: "1.5rem"
    },
    iconoCentral: {
        width: '200px',
        marginLeft: '-100px',
        [theme.breakpoints.down("sm")]: {
            display: "none"
        }
    },
    fondoImagen: {
        background: `url(${fondoImagenUrl}) no-repeat`,
        backgroundSize: "cover",
        height: '960px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        [theme.breakpoints.down("sm")]: {
            height: "100vh",
            display: "block",
            position: "fixed",
            top: 0,
            width: "100%",
            backgroundPosition: "center",
        }
    },
    containerTab: {
        padding: "10px 7rem 10px 5rem", 
        display: "flex", 
        flexDirection: "column", 
        alignItems: "flex-start",
        [theme.breakpoints.down("sm")]: {
            padding: "10px 1rem 10px 1rem", 
            height: "initial",
            overflow: "initial",
            overflowX: "initial",
            backgroundColor: "rgba(255,255,255,1)",
            position: "relative",
            zIndex: "1",
            minHeight: "calc(100vh - 160px)",
        }
    }
})

class LandingError extends Component{
    constructor(props){
        super(props);
        this.state = {
            currentPath: window.location.hash.split('/')[1],
        }
    }
    componentDidMount(){
        console.log('preference_id from props', this.props.preference_id);
        if(document.querySelector('.mp-mercadopago-checkout-wrapper')){
            document.querySelector('.mp-mercadopago-checkout-wrapper').remove();
        }
    }
    
    toRetry(){
        if(this.state.currentPath != '' && !window.location.hash.split('/')[1].includes("landing") ){
            console.log('currentPath', this.state.currentPath);
            this.props.history.push('/'+this.state.currentPath);
        }
        else{
            this.props.history.push('/');
        }
        

        // if(this.props.preference_id){
        //     await global.config.mercadopago.checkout({
        //         preference: {
        //             id: this.props.preference_id
        //         },
        //         theme: {
        //             elementsColor: '#0087C2',
        //             headerColor: '#2D2D2D',
        //         },
        //         autoOpen: true,
        //     });
        // } 
    }
    currentMainColor(){
        if (this.state.currentPath == "virgin") {
          return "#EE0001";
          
        }
        else if (this.state.currentPath == "digitek") {
            return "#444444";
            
        }
        else if (this.props.currentPath == "losheroes") {
            return "#3d64a3";
            
        }
        else if (this.props.currentPath == "h2o") {
            return "#15669D";
            
        }
        else if (this.props.currentPath == "maconline") {
            return "#15669D";   
        }
        else if (this.props.currentPath == "iphone") {
            return "#15669D";   
        }
        else {
          return "#15669D";
        }
    }
      currentIconoCentral(){
        if (this.state.currentPath == "virgin") {
          return require("../images/icono_central_virgin.png");
          
        }
        else if (this.state.currentPath == "digitek") {
            return require("../images/icono_central_digitek.png");
            
        }
        else if (this.state.currentPath == "losheroes") {
            return iconoCentral;
            
        }
        else if (this.state.currentPath == "h2o") {
            return iconoCentral;
            
        }
        else if (this.props.currentPath == "maconline") {
            return iconoCentral;
        }
        else if (this.props.currentPath == "iphone") {
            return iconoCentral;
        }
        else {
          return iconoCentral;
        }
    }
    mainWordPhone(){
        if (this.state.currentPath == "iphone") {
          return 'iPhone';
        }
        else if (this.state.currentPath == "maconline") {
          return 'iPhone';
        }
        else {
          return 'Smartphone';
        }
      }
    
      mainBackgroundImage(){
        if (this.state.currentPath == "iphone") {
          return require("../images/productos_iphone.png");
        }
        else if (this.state.currentPath == "maconline") {
          return require("../images/productos_iphone.png");
        }
        else {
          return fondoImagenUrl;
        }
      }
    render(){
        const { classes } = this.props;
        return (
            <>
            <Topbar currentPath = {this.state.currentPath} />
            <Grid container spacing={0}>
                <Grid  item xs={12} sm={12} md={6}>
                    <div className={classes.containerTab}>
                        <Typography style={{marginTop: "2rem", color: this.currentMainColor()}} className={classes.tituloPrincipal1}>
                            Ups!
                        </Typography>
                        <Typography className={classes.tituloPrincipal2} style={{ color: this.currentMainColor() }}>
                            Ha ocurrido un error
                        </Typography>
                    
                        <Button onClick={() => this.toRetry()} color="primary" style={{fontFamily: "Source Sans Pro", fontWeight: "bold", fontSize: "24px", textTransform: "capitalize", marginBottom: "2rem", alignSelf: "stretch", maxWidth: "450px", padding: "1rem 0rem", marginTop: "2rem", backgroundColor: this.currentMainColor()}} variant="contained" disabled={false} >
                            Volver al Inicio
                        </Button>
                    </div>
                </Grid>
                <Grid className={classes.fondoImagen } item xs={12} sm={12} md={6} style={{backgroundImage: `url(${this.mainBackgroundImage()})`}}>
                    <img className={classes.iconoCentral} src={this.currentIconoCentral()} alt="icono"></img>
                </Grid>
            </Grid>
            <FooterGarnet />
            </>
        );
    }
}
const  mapStateToProps = (state) => ({
    preference_id: state.preference_id
});

export default connect(mapStateToProps)(withRouter(withStyles(styles)(LandingError)));
