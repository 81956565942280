import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/styles/withStyles";
import { Link, withRouter } from "react-router-dom";
import { Container } from "@material-ui/core";
import { WhatsApp, MailOutline, Phone } from '@material-ui/icons';

const logoGarnet = require("../images/PowerGarnetek_b.png");

const styles = theme => ({
    flexAlignCenter: {
      display: "flex",
      alignItems: "center"
    },
    flexJustifySpaceBetween: {
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap"
    },
    footer: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "17px",
        lineHeight: "21px",
        color: "#878787",
        position: "relative",
    },
    gridContainerFooter: {
        padding: "18px 5rem", 
        background: "#F4F4F4",
        [theme.breakpoints.down("sm")]: {
            padding: "18px 18px",
        }
    }
})

class FooterGarnet extends Component {
    
    render(){
        const { classes } = this.props;
        return (
            <footer className={classes.footer}>
                
                    <Grid container className={classes.gridContainerFooter}>
                        <Grid item xs={12} sm={6} md={6} className={classes.flexAlignCenter}>
                        <img
                            style={{marginRight: "10px", opacity: "0.5", width: "80px"}}
                            src={logoGarnet}
                            alt="garnet"
                        />
                            Seguros intermediados por Garnet SpA
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} >
                            <div className={classes.flexJustifySpaceBetween}>
                                <div className={classes.flexAlignCenter}>
                                    <Phone style={{color: "#298DC0", marginRight: "5px"}}></Phone> Contacto: 232233326
                                </div>
                                <div className={classes.flexAlignCenter}>
                                    <MailOutline style={{color: "#298DC0", marginRight: "5px"}}></MailOutline> contacto@grupogarnet.com
                                </div>
                                <div className={classes.flexAlignCenter}>
                                    <WhatsApp style={{color: "#298DC0", marginRight: "5px"}}></WhatsApp> +5693539 0298
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                
            </footer>
        );
    }
}

export default withRouter(withStyles(styles)(FooterGarnet));
