import React from 'react'
import { Route, HashRouter, Switch } from 'react-router-dom'
import Dashboard from './components/Dashboard'
import Wizard from './components/Wizard'
import Cards from './components/Cards'
import Main from './components/Main'
import Signup from './components/Signup'
import ScrollToTop from './components/ScrollTop'
// Pages
import Landing1 from './pages/Landing1'
import LandingError from './pages/LandingError'
import LandingExito from './pages/LandingExito'
import LandingPendiente from './pages/LandingPendiente'
import LandingLoading from './pages/LandingLoading'


export default props => (
    <HashRouter>
      <ScrollToTop>
        <Switch>
          <Route exact path='/' component={ Landing1 } />
          <Route exact path='/virgin' component={ Landing1 } />
          <Route exact path='/virgin/landing-error' component={ LandingError } />
          <Route exact path='/virgin/landing-exito' component={ LandingExito } />
          <Route exact path='/virgin/landing-pendiente' component={ LandingPendiente } />
          <Route exact path='/virgin/landing-loading' component={ LandingLoading } />

          <Route exact path='/digitek' component={ Landing1 } />
          <Route exact path='/digitek/landing-error' component={ LandingError } />
          <Route exact path='/digitek/landing-exito' component={ LandingExito } />
          <Route exact path='/digitek/landing-pendiente' component={ LandingPendiente } />
          <Route exact path='/digitek/landing-loading' component={ LandingLoading } />

          <Route exact path='/losheroes' component={ Landing1 } />
          <Route exact path='/losheroes/landing-error' component={ LandingError } />
          <Route exact path='/losheroes/landing-exito' component={ LandingExito } />
          <Route exact path='/losheroes/landing-pendiente' component={ LandingPendiente } />
          <Route exact path='/losheroes/landing-loading' component={ LandingLoading } />

          <Route exact path='/h2o' component={ Landing1 } />
          <Route exact path='/h2o/landing-error' component={ LandingError } />
          <Route exact path='/h2o/landing-exito' component={ LandingExito } />
          <Route exact path='/h2o/landing-pendiente' component={ LandingPendiente } />
          <Route exact path='/h2o/landing-loading' component={ LandingLoading } />

          <Route exact path='/maconline' component={ Landing1 } />
          <Route exact path='/maconline/landing-error' component={ LandingError } />
          <Route exact path='/maconline/landing-exito' component={ LandingExito } />
          <Route exact path='/maconline/landing-pendiente' component={ LandingPendiente } />
          <Route exact path='/maconline/landing-loading' component={ LandingLoading } />

          <Route exact path='/iphone' component={ Landing1 } />
          <Route exact path='/iphone/landing-error' component={ LandingError } />
          <Route exact path='/iphone/landing-exito' component={ LandingExito } />
          <Route exact path='/iphone/landing-pendiente' component={ LandingPendiente } />
          <Route exact path='/iphone/landing-loading' component={ LandingLoading } />
          
          
          {/* <Route exact path='/main' component={ Main } />
          <Route exact path='/dashboard' component={ Dashboard } />
          <Route exact path='/signup' component={ Signup } />
          <Route exact path='/wizard' component={ Wizard } />
          <Route exact path='/cards' component={ Cards } /> */}
          <Route exact path='/landing-1' component={ Landing1 } />
          <Route exact path='/landing-error' component={ LandingError } />
          <Route exact path='/landing-exito' component={ LandingExito } />
          <Route exact path='/landing-pendiente' component={ LandingPendiente } />
          <Route exact path='/landing-loading' component={ LandingLoading } />
        </Switch>
      </ScrollToTop>
    </HashRouter>
  )