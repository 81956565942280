import React, { Component } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { withRouter, useLocation } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Topbar from "../components/TopbarGarnet";
import FooterGarnet from "../components/FooterGarnet";
import Button from "@material-ui/core/Button";
import { ArrowBack, CloudDownload } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import API from "../api/axios";
import Zoom from "@material-ui/core/Zoom";
import regionesComunas from "../data-utils/regiones-comunas";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import ReactDOM from "react-dom";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import HelpIcon from "@material-ui/icons/Help";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";

import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import Loading from "../components/common/Loading";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const fondoImagenUrl = require("../images/productos_apple.png");
const iconoCentral = require("../images/icono_central.png");
const logoMercadopago = require("../images/mercadopago.svg");
const pdfCondicionesGarantiaExtendida = require("../pdfs/chubb_garnetek_seguro_telefonos_moviles.pdf");
const pdfCondicionesRobo = require("../pdfs/CP MAC Seguro de Robo Equipos.pdf");

const iconoFeatures1 = require("../images/icons/002-smash.png");
const iconoFeatures2 = require("../images/icons/006-security-1_v2.png");
const iconoFeatures3 = require("../images/icons/002-flying-money-1.png");
const iconoFeatures4 = require("../images/icons/010-shield-1.png");
const iconoFeaturesList = require("../images/icons/004-trusted.png");
const iconoFeaturesCondition = require("../images/icons/check.png");
const iconoFeaturesConditionShield = require("../images/icons/006-shield.png");

var _ = require("lodash");

const styles = (theme) => ({
  fondoImagen: {
    background: `url(${fondoImagenUrl}) no-repeat`,
    backgroundSize: "cover",
    height: "calc(100vh - 126px)",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      height: "100vh",
      display: "block",
      position: "fixed",
      top: 0,
      width: "100%",
      backgroundPosition: "center",
    },
  },
  iconoCentral: {
    width: "200px",
    marginLeft: "-100px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  tituloPrincipal: {
    fontFamily: "Source Sans Pro",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "37px",
    lineHeight: "1.2",
    color: "#0087C2",
    marginBottom: "1rem",
  },
  labelSelect: {
    fontFamily: "Source Sans Pro",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "118.3%",
    color: "#2D2D2D",
    marginBottom: "1rem",
  },
  tituloPlan: {
    fontFamily: "Source Sans Pro",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "26px",
    color: "#298DC0",
  },
  textoPlan: {
    fontFamily: "Source Sans Pro",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "26px",
    color: "#474747",
    marginBottom: "1.5rem",
  },
  titleH2Plan: {
    fontFamily: "Source Sans Pro",
    color: "#474747",
    fontSize: "21px",
  },
  titleH3Plan: {
    fontFamily: "Source Sans Pro",
    fontSize:" 1.17em",
    fontWeight: "600",
    margin: "1em 0",
    display: "block",
  },
  textoPlanDetalle: {
    fontFamily: "Source Sans Pro",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "26px",
    color: "#474747",
    marginBottom: "1.5rem",
  },
  textoPlan2Detalle: {
    fontFamily: "Source Sans Pro",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "26px",
    color: "#2D2D2D",
    marginBottom: "1.5rem",
  },
  precioPlan: {
    fontFamily: "Source Sans Pro",
    fontStyle: "bold",
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "26px",
    color: "#298DC0",
    marginBottom: "1rem",
  },
  precioPlanRef: {
    fontFamily: "Source Sans Pro",
    fontStyle: "bold",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "26px",
    color: "#298DC0",
    marginBottom: "1rem",
  },
  precioPlanDetalle: {
    fontFamily: "Source Sans Pro",
    fontStyle: "bold",
    fontWeight: "700",
    fontSize: "36px",
    lineHeight: "26px",
    color: "#298DC0",
    marginBottom: "1rem",
  },
  tarjetaPlan: {
    marginBottom: "1rem",
    padding: "10px",
    background: "#FFFFFF",
    border: "2px dashed #298DC0",
    boxSizing: "border-box",
    borderRadius: "5px",
    "&:lastChild": { border: "2px solid #298DC0" },
    "&:hover": {
      border: "2px solid #298DC0",
      background: "#298DC0",
      cursor: "pointer",
    },
    "&:hover > p": { color: "#FFFFFF !important" },
    // "&:hover > p": { color: "#FFFFFF !important" },
    // "&:hover > h2": { color: "#FFFFFF !important" },
    // "&:hover > .titleH3Plan": { color: "#FFFFFF !important" },
    width: "45%",
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
   
  },
  containerTab: {
    padding: "10px 7rem 10px 5rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 1rem 10px 1rem",
    },
  },
  columnaIzquierda: {
    height: "calc(100vh - 126px)",
    overflow: "scroll",
    overflowX: "hidden",
    [theme.breakpoints.down("sm")]: {
      height: "initial",
      overflow: "initial",
      overflowX: "initial",
      backgroundColor: "rgba(255,255,255,1)",
      position: "relative",
      zIndex: "1",
      minHeight: "calc(100vh - 160px)",
    },
  },
  containerGrid: {
    width: "100%",
    margin: 0,
    padding: 0,
  },
  iconmoLogoDemo:{
    width: "20px",
    marginRight: "10px",
  },
  imagesFormBlocks: {
    display: "flex",
    flexDirection: "column",
    paddingRight: "5px",
    marginBottom: "1rem",
    border: "1px dashed #9e9e9e",
    paddingLeft: "5px",
    paddingTop: "5px",
    paddingBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "0px",
      marginBottom: "0px",
    },
  },
    progessBarTop: {
        width: "calc(100% - 20px)",
        border: "1px solid #b0c5db",
        marginTop: "5px",
        height: "10px",
        borderRadius: "2px",
        marginLeft: "10px",
        marginRight: "10px",
        marginBottom: "10px",

    },
    activeStep: {
        background: "#b0c5db",
    },
    iconoFeatures: {
        textAlign: "center",
        marginBottom: "15px",
    },
    iconoFeaturesText: {
        padding: "0 1rem",
        lineHeight: "1.2",
        fontSize: "14px",
        
    },
    listFeatures: {
        // background: "#dfe6ed",
        padding: "5px 15px",
    },
    listFeaturesItemIcon: {
        width: "100%",
    },
    ListFeaturesText: {
        fontSize: "14px",
        paddingLeft: "10px",
        lineHeight: "1.1",
    },
    listFeaturesItem:{

    },
    subTituloPrincipalText:{
        fontFamily: "Source Sans Pro",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "13px",
        lineHeight: "1",
        color: "#354851",
        marginBottom: "2rem",
        width: "calc(100% - 20px)",
        padding: "0 10px",
    },
    subTituloPrincipal:{
        color: "#354851",
        fontSize: "24px",
        fontFamily: "Source Sans Pro",
        fontWeight: "bold",
        width: "calc(100% - 20px)",
        padding: "0 10px",
        marginBottom: "0px",
    },
    conditionFeaturesList:{
        background: "#dfe6ed",
        padding: "30px 0 20px 0",
        borderRadius: "30px 0 30px 0",
        marginBottom: "20px",
    },
    conditionFeatureIconShield:{
        width: "40px",
        marginLeft: "5px",
        marginBottom: "30px",
        opacity: "0.9",
    },
    conditionFeatures:{
        marginBottom: "20px",
        width: "calc(100% - 40px)",
        display: "inline-block",
        padding: "0px 20px 0px 20px",
    },
    conditionFeatureIcon:{
        width: "20px",
        float: "left",
        marginRight: "10px",
        marginBottom: "25px",
        [theme.breakpoints.down("sm")]: {
        marginBottom: "25px",
        },
    },
    conditionFeatureText:{
        fontSize: "18px",
        display: "block",
        fontWeight: "bold",
        color: "#000",
    },
    iconDowloadDocument:{
        width: "35px",
        marginLeft: "15px",
    },
    checkoutStep:{
        width: "100%",
        background: "#dfe6ed",
        padding: "10px 0",
        marginBottom: "20px",
        
    },
    checkoutStepIcon:{
        width: "30px",
        marginLeft: "10px",
    },
    checkoutStepText:{
        fontSize: "20px",
        fontWeight: "bold",
    },
    homeBoxFeatures:{
        background:"#dfe6ed",
        padding: "10px 0px",
        margin:"0 auto 20px",
        borderRadius:"10px",
        maxWidth:"430px",
    },
    homeInputForm:{
        width: "400px",
        margin: "0 auto",
        [theme.breakpoints.down("sm")]: {
        minWidth: "360px",
        width: "auto",
        },
    },
    homeButtonForm:{
        fontFamily: "Source Sans Pro",
        fontWeight: "bold",
        fontSize: "24px",
        textTransform: "capitalize",
        marginBottom: "2rem",
        alignSelf: "stretch",
        width: "400px",
        margin: "0 auto",
        padding: "1rem 0rem",
        [theme.breakpoints.down("sm")]: {
        minWidth: "360px",
        width: "auto",
        },
    }
});
const queryString = require("query-string");
class Landing1 extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      isLoading: false,
      loading: false,
      precio1: "",
      imei: "",
      producto: "",
      precio: "",
      productosList: [],
      preciosList: [],
      planes: [],
      activeTab: 1,
      planesEncontrados: [],
      planSeleccionado: undefined,
      regionesList: [],
      comunasList: [],
      testField: "",
      "N° Guia de despacho": "",
      "N° de pedido": "",
      RUT: "",
      Nombres: "",
      "Apellido Paterno": "",
      "Apellido Materno": "",
      "Fecha de Nacimiento": "",
      Dirección: "",
      Región: "",
      Comuna: "",
      Teléfono: "",
      Email: "",
      condicion1: false,
      condicion3: false,
      condicion4: false,
      isFormValid: false,
      openSnackbar: false,
      mensajesError: [],
      showBotonPagarMercadopago: false,
      campos_formulario_venta: [],
      labelWidth: 0,
      open: false,
      setOpen: false,
      user_param: null,
      currentPath: window.location.hash.split('/')[1],
      hoverCard : false,
      landing: window.location.hash.split('/')[1],
      landing_url: window.location.href.replaceAll('/#/',''),
      introFormValid : false,

    };
  }
  componentDidMount() {
    /*
    this.setState({
      labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
    });
    */
   var urlhash = window.location.hash;
    console.log(window.location.hash);
    urlhash = urlhash.replace('/?','');
    console.log(urlhash);
    var values = queryString.parse(urlhash);
    console.log(values);
    
    if (values.user) {
      this.setState({
        user_param: values.user,
      });
    }
    this.setState({
      regionesList: regionesComunas.regiones.map((e) => e.region),
    });
  }
  handleClickOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }
  handleChangeProducto(event) {
    this.setState({
      producto: event.target.value,
      precio: "",
      preciosList: [],
    });
    const preciosSet = new Set();
    this.state.planes
      .filter(
        (e) =>
          e.materia.descripcion == event.target.value &&
          e.name.includes("desde") &&
          e.name.includes("hasta")
      )
      .map((e) => e.name.slice(e.name.indexOf("desde")))
      .forEach((e) => {
        preciosSet.add(e);
      });
    const preciosArray = [];
    preciosSet.forEach((e) => {
      preciosArray.push(e);
    });
    this.setState({ preciosList: preciosArray });
  }
  handleChangePrecio(event) {
    console.log({ precio: event.target.value });
    this.setState({ precio: event.target.value });
  }
  toFirstTab() {
    console.log("toFirstTab");
    this.setState({ activeTab: 1 });
  }
  toSecondTab() {
    this.setState({ loading: true });

    console.log("toSecondTab");
    if (this.state.imei) {
      
      API({
        url: "api/landing/mtek/plans/by_imei",
        method: "POST",
        data: { imei: this.state.imei },
      })
        .then((response) => {
          /*
        this.setState({ planes: response.data });
        const productosSet = new Set();
        response.data.forEach((e) => {
          productosSet.add(e.materia.descripcion);
        });
        const productosArray = [];
        productosSet.forEach((e) => {
          productosArray.push(e);
        });
        this.setState({ productosList: productosArray });
        */
       console.log(response.data);
       this.setState({ loading: false });
          if (!response.data) {
            
            this.setState({ loading: false });
            const mensajesError = [
              {
                active: true,
                message: "No encontramos resultados. Intenta con otro IMEI.",
              },
            ];
            this.setState({ mensajesError });
          } else {

            this.setState({ loading: false });
            this.setState(
              {
                planesEncontrados: response.data.campaigns.map((e) => ({
                  ...e.plan,
                  price: (response.data.price),
                  pricePesos: (response.data.pricePesos),
                  dateUf: (response.data.dateUf),
                  valueUf: (response.data.valueUf),
                  id: response.data.id,
                })),
              },
              () => {
                if (this.state.planesEncontrados.length) {
                  this.setState({ activeTab: 2 });
                } else {
                  const mensajesError = [
                    {
                      active: true,
                      message:
                        "No encontramos resultados. Intenta con otros valores.",
                    },
                  ];
                  this.setState({ mensajesError });
                }
              }
            );
            this.sendIntroForm(response.data.make, response.data.model);
          }
        })
        .catch((e) => {
          this.setState({ loading: false });
          console.log(e);
          const mensajesError = [
            {
              active: true,
              message: "No se han encontrado planes para este equipo",
            },
          ];
          this.setState({ mensajesError });
        });
    }
  }
  seleccionarPlan(plan) {
    this.setState({ loading: true });
    this.setState({
      planesEncontrados: this.state.planesEncontrados.map((e) => {
        e.isActive = plan.id == e.id;
        return e;
      }),
    });
    /*
    let atributosMateriaTotal = JSON.parse(plan.materia.atributos);
    console.log("atributosMateriaTotal", atributosMateriaTotal);
    let atributosMateria = [];
    // Campos provenientes de la materia
    atributosMateriaTotal.forEach((a) => {
      if (a.momento == "venta") {
        atributosMateria.push({
          name: _.capitalize(a.nombre),
          attr_type: "materia",
          data_type: "string",
        });
      }
    });
    // Campos provenientes de la subcategoria (para filtrar en el POS en vista de nueva venta)
    plan.plansubcategoria.searchablesattrs.forEach((sa) => {
      atributosMateria.push({
        name: _.capitalize(sa.public_name),
        attr_type: "subcategoria",
        data_type: sa.data_type_input,
      });
    });
    //console.log('atributosMateria', atributosMateria);
    let obj = new Object();
    atributosMateria.forEach((am) => {
      if (am.name.toLowerCase() == "el valor del equipo") {
        obj[am.name] = this.state.precio1;
      } else {
        obj[am.name] = "";
      }
    });
    */
    //console.log('obj', obj);
    //this.setState({ ...obj }, () => {
    //this.setState({ campos_formulario_venta: atributosMateria });
    this.setState({ planSeleccionado: plan });
    //});
    this.myRef.current.scrollIntoView({ behavior: "smooth" });

    this.setState({ loading: false });
  }
  toThirdTab() {
    this.setState({ loading: true });
    console.log("toThirdTab");
    if (this.state.planSeleccionado) {
      this.setState({ activeTab: 3 });
    }
    this.setState({ loading: false });
  }
  toForthTab() {
    this.setState({ loading: true });
    console.log("toThirdTab");
    if (this.state.planSeleccionado) {
      this.setState({ activeTab: 4 });
    }
    this.setState({ loading: false });
  }
  handleChangeTextField(event, property) {

    if (property == "imei") {
      console.log('aimeiimimim');
      const re = /^[0-9\b]+$/;
      if (!re.test(event.target.value)) {
        event.target.value = '';
      }
    }
    
    let obj = new Object();
    obj[property] = event.target.value;
    this.setState({ ...obj });
    if (property) {
      console.log("valid");
      this.validationInput(event, property);
    }

    if (property == "Email") {
      if ( /\S+@\S+\.\S+/.test(event.target.value) ) {
        this.setState({ introFormValid: true });
      }
      else{
        this.setState({ introFormValid: false });
      }
    }

  }
  handleChangeCheckbox(event, property) {
    let obj = new Object();
    obj[property] = event.target.checked;
    this.setState({ ...obj });
  }
  handleChangeRegion(event) {
    this.setState({ Región: event.target.value, Comuna: "", comunasList: [] });
    this.setState({
      comunasList: regionesComunas.regiones.find(
        (e) => e.region == event.target.value
      ).comunas,
    });
  }
  handleSubmit(event) {
    event.preventDefault();
    console.log("onSubmit");
    this.setState({ isLoading: true }, () => {
      this.sendForm().catch((e) => {
        console.log(e);
      });
    });
  }
  sendForm() {
    this.setState({ loading: true });
    return new Promise((resolve, eject) => {
      const formData = _.pick(this.state, [
        "RUT",
        "Nombres",
        "Apellido Paterno",
        "Apellido Materno",
        "Fecha de Nacimiento",
        "Dirección",
        "Región",
        "Comuna",
        "Teléfono",
        "Email",
        "planSeleccionado",
        "imei",
        "landing",
        "landing_url"
      ]);
      formData.user = this.state.user_param ? this.state.user_param : null;
      API({
        url: "api/flow/multichubb/checkout",
        method: "POST",
        data: formData,
      })
        .then(async (response) => {
          //console.log(response.data);
          //alert("Se redirigirá a: " + response.data);
          setTimeout(() => {
            window.location.href = response.data;
          }, 1500);

          /*
          this.props.dispatch({
            type: "SET_PREFERENCE_ID",
            value: 'response.data.preference_id', // OJO AQUI <<<<<<<<<<<
          });
          */
          this.setState({ isLoading: false }, () => {
            resolve();
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log(error);
          this.setState({ isLoading: false });
          console.log("error.response.status", error.response.status);
          if (error.response.status == 422) {
            if (error.response.data) {
              if (error.response.data.errors) {
                if (Object.keys(error.response.data.errors).length) {
                  const mensajesError = Object.keys(
                    error.response.data.errors
                  ).map((key) => {
                    return {
                      active: true,
                      message: error.response.data.errors[key][0],
                    };
                  });
                  this.setState({ mensajesError }, () => {});
                  eject(error);
                  return;
                }
              }
            }
          }
          // const mensajesError = [
          //   {
          //     active: true,
          //     message:
          //       "Ha ocurrido un error en el servidor. Reintente mas tarde.",
          //   },
          // ];
          // this.setState({ mensajesError }, () => {});
          // eject(error);
        });
    });
  }
  sendIntroForm(make, model) {

    let landing_param = "garnetekmobile";
    if (this.state.landing != '' ) {
      landing_param = this.state.landing;
    }

    var introForm = {
      "imei": this.state.imei,
      "make": make,
      "model": model,
    }
    API({
      url: "api/v2/introform",
      method: "POST",
      data: 
        {
          "landing":landing_param,
          "email": this.state["Email"],
          "info": JSON.stringify(introForm)
        }
      
    })
  }
  handleClickBotonPagar() {
    this.setState({ isLoading: true }, () => {
      this.sendForm();
    });
  }
  handleCloseSnackbar(index) {
    let mensajesError = this.state.mensajesError.map((e, i) => {
      if (i == index) {
        return { ...e, active: false };
      } else {
        return e;
      }
    });
    this.setState({ mensajesError: mensajesError });
  }
  currentMainColor(){
    if (this.state.currentPath == "virgin") {
      return "#EE0001";
      
    }
    else if (this.state.currentPath == "digitek") {
      return "#444444";
      
    }
    else if (this.props.currentPath == "losheroes") {
      return "#3d64a3";
      
    }
    else if (this.state.currentPath == "h2o") {
      return "#15669D";
      
    }
    else if (this.props.currentPath == "maconline") {
      return "#15669D";
    }
    else if (this.props.currentPath == "iphone") {
      return "#15669D";
    }
    else {
      return "#15669D";
    }
  }
  currentIconoCentral(){
    if (this.state.currentPath == "virgin") {
      return require("../images/icono_central_virgin.png");
      
    }
    else if (this.state.currentPath == "digitek") {
      return require("../images/icono_central_digitek.png");
      
    }
    else if (this.state.currentPath == "losheroes") {
      return iconoCentral;
      
    }
    else if (this.state.currentPath == "h2o") {
      return iconoCentral;
      
    }
    else if (this.state.currentPath == "maconline") {
      return iconoCentral;
    }
    else if (this.state.currentPath == "iphone") {
      return iconoCentral;
    }
    else {
      return iconoCentral;
    }
  }
  mainWordPhone(){
    if (this.state.currentPath == "iphone") {
      return 'iPhone';
    }
    else if (this.state.currentPath == "maconline") {
      return 'iPhone';
    }
    else {
      return 'Smartphone';
    }
  }

  mainBackgroundImage(){
    if (this.state.currentPath == "iphone") {
      return require("../images/productos_iphone.png");
    }
    else if (this.state.currentPath == "maconline") {
      return require("../images/productos_iphone.png");
    }
    else {
      return fondoImagenUrl;
    }
  }

  handleMouseEnterCard = () => {
    this.setState({ hoverCard: true });
  }
  handleMouseLeaveCard = () => {
    this.setState({ hoverCard: false });
  }
  validationInput(event, property){
    console.log("validationInput", property);
    console.log("this.state[event]", event.target.value);
    let formInputErrorSt = false;
    let formInputErrorMsg = false;
    switch(property) {
      case 'Fecha de compra':
        const pastTime = new Date(event.target.value);
        const now = new Date();
        console.log("pastTime", pastTime);
        console.log("now", now);
        const sixMontsInMs = 6 * 30 * 24 * 60 * 60 * 1000;
        const timeDiffInMs = now.getTime() - pastTime.getTime();

        if(timeDiffInMs >= sixMontsInMs){
          formInputErrorSt = true;
          formInputErrorMsg = "La fecha de compra debe ser menor a 6 meses";
          console.log("formInputError", formInputErrorSt);
          console.log("formInputErrorMsg", formInputErrorMsg);
        }
        break;
      case 'Fecha de Nacimiento':
          var optimizedBirthday = event.target.value.replace(/-/g, "/");
          console.log("optimizedBirthday", optimizedBirthday);
          var myBirthday = new Date(optimizedBirthday);
          console.log("myBirthday", myBirthday);
          var currentDate = new Date().toJSON().slice(0,10)+' - 03:00:00';
          console.log("currentDate", currentDate);
          var myAge = ~~((Date.now(currentDate) - myBirthday) / (31557600000));
          console.log("myAge", myAge);
          if(myAge < 18){
            formInputErrorSt = true;
            formInputErrorMsg = "El Seguro debe ser tomado por un mayor de edad";
            console.log("formInputError", formInputErrorSt);
            console.log("formInputErrorMsg", formInputErrorMsg);
          }
        break;
      default:
        break;
    }

    // this.setState(this.formValid, formInputError);
    this.setState({ formInputError: formInputErrorSt });
    

    if(formInputErrorSt){
      const mensajesError = [
        {
          active: true,
          message:formInputErrorMsg,
        },
      ];
      this.setState({ mensajesError }, () => {});
    }
    else{
      const mensajesError = [
        {
          active: false,
          message:'',
        },
      ];
      this.setState({ mensajesError }, () => {});
    }

  }
  openInNewTab(url){
    console.log("url", url);
    window.open(url, '_blank', 'noreferrer');
  }
  render() {
    const { classes } = this.props;
    const formValid =
      !this.state.isLoading &&
      !!this.state["RUT"] &&
      !!this.state["Nombres"] &&
      !!this.state["Apellido Paterno"] &&
      !!this.state["Apellido Materno"] &&
      !!this.state["Fecha de Nacimiento"] &&
      !!this.state["Dirección"] &&
      !!this.state["Región"] &&
      !!this.state["Comuna"] &&
      !!this.state["Teléfono"] &&
      !!this.state["Email"] &&
      !!this.state.condicion1 &&
      // !!this.state.condicion3 &&
      // !!this.state.condicion4 &&
      this.state.campos_formulario_venta.every((e) => !!this.state[e.name]);
    let pdfTerminosCondiciones = "";
    if (this.state.planSeleccionado) {
      pdfTerminosCondiciones = this.state.planSeleccionado.name
        .toLowerCase()
        .includes("robo")
        ? pdfCondicionesRobo
        : pdfCondicionesGarantiaExtendida;
    } else {
      pdfTerminosCondiciones = "http://www.maconline.cl";
    }
    

    return (
      <>
      <Loading loading={this.state.loading} />
        <Dialog
          open={this.state.open}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.handleClose()}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle disableTypography>
            <Typography variant="subtitle1" gutterBottom>
            Para conocer tu número IMEI puedes digitar *#06#* en el teléfono. Aparecerá una pantalla con esta y otras informaciones sobre el equipo.
            <br></br>
            <br></br>
            También puedes copiar y pegar el número IMEI de manera sencilla, dirígete a:
            <br></br>
            1-Ajustes de teléfono
            <br></br>
            2-Acerca del teléfono
            <br></br>
            3- Ubica el numero IMEI y mantén presionado unos segundos.
            <br></br>
            <br></br>
            Puedes ver los videos a modo de ejemplo:
            </Typography>
            <Typography variant="contained" gutterBottom>
              <Button 
              color="primary"
              style={{
                marginRight: "5px",
                fontSize: "12px",
                marginBottom: "5px",
              }}
              variant="contained"
              onClick={() => this.openInNewTab('https://garnetek.s3.us-west-1.amazonaws.com/mobile/IMEI_IPHONE.mp4')}

              >
                <img
              className={classes.iconmoLogoDemo}
              src={require("../images/logo_ios.png")}
              alt="icono"
            ></img>

                  Ver Video
              </Button>

              <Button 
              color="primary"
              style={{
                fontSize: "12px",
                marginBottom: "5px",
              }}
              variant="contained"
              onClick={() => this.openInNewTab('https://garnetek.s3.us-west-1.amazonaws.com/mobile/IMEI_ANDROID.mp4')}
              >
                <img
              className={classes.iconmoLogoDemo}
              src={require("../images/logo_android.png")}
              alt="icono"
              
            ></img>

                  Ver Video
              </Button>
            </Typography>
          </DialogTitle>
          <DialogActions>
            <Button onClick={() => this.handleClose()} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <Topbar currentPath = {this.state.currentPath} />
        <Grid className={classes.containerGrid} container spacing={0}>
          <Grid
            className={classes.columnaIzquierda}
            item
            xs={12}
            sm={12}
            md={6}
          >
            {this.state.activeTab === 1 && (
              <Zoom in={true} timeout={400}>
                <div className={classes.containerTab}>


                <Grid className={classes.progessBarTop} container>
                <Grid xs={6} sm={6} md={6} className={classes.activeStep} ></Grid>
                <Grid xs={6} sm={6} md={6} ></Grid>
                </Grid>
                  
                  <Typography className={classes.tituloPrincipal} style={{textAlign: "center", width: "100%"}} >
                  Despreocúpate y disfruta tu {this.mainWordPhone()}
                  </Typography>



                  <Grid  container>
                    <Grid xs={6} sm={6} md={6} className={classes.iconoFeatures}> <img  src={iconoFeatures1} alt="icono"></img><Typography  className={classes.iconoFeaturesText}>Daño</Typography></Grid>
                    <Grid xs={6} sm={6} md={6} className={classes.iconoFeatures}> <img  src={iconoFeatures2} alt="icono"></img><Typography className={classes.iconoFeaturesText}>Robo</Typography></Grid>
                  </Grid>
                  <Grid  container>
                    <Grid xs={6} sm={6} md={6} className={classes.iconoFeatures}> <img  src={iconoFeatures3} alt="icono"></img><Typography  className={classes.iconoFeaturesText}>Gastos extra generados</Typography></Grid>
                    <Grid xs={6} sm={6} md={6} className={classes.iconoFeatures}> <img  src={iconoFeatures4} alt="icono"></img><Typography className={classes.iconoFeaturesText}>Protegido</Typography></Grid>
                  </Grid>
    
                  <div className={classes.homeBoxFeatures}>
                  <Typography className={classes.tituloPrincipal} style={{textAlign: "center", marginBottom:"10px",fontSize:"22px", lineHeight: "1", marginTop:"5px"}} >
                  ¿Por qué contratar Seguro de {this.mainWordPhone()}?
                  </Typography>
                  <Grid  container className={classes.listFeatures}>
                    <Grid xs={1} sm={1} md={1} className={classes.listFeaturesItem}> <img  src={iconoFeaturesList} className={classes.listFeaturesItemIcon} alt="icono"></img></Grid>
                    <Grid xs={11} sm={11} md={11} className={classes.listFeaturesItem} ><Typography  className={classes.ListFeaturesText}>Un {this.mainWordPhone()} es tu inversión y el riesgo de robo y/daño es evidente</Typography></Grid>
                  </Grid>
                  <Grid  container className={classes.listFeatures}>
                    <Grid xs={1} sm={1} md={1} className={classes.listFeaturesItem}> <img  src={iconoFeaturesList} className={classes.listFeaturesItemIcon} alt="icono"></img></Grid>
                    <Grid xs={9} sm={9} md={9} className={classes.listFeaturesItem} ><Typography  className={classes.ListFeaturesText}>Por una cuota mensual de bajo costo la puedes proteger</Typography></Grid>
                  </Grid>
                  <Grid  container className={classes.listFeatures}>
                    <Grid xs={1} sm={1} md={1} className={classes.listFeaturesItem}> <img  src={iconoFeaturesList} className={classes.listFeaturesItemIcon} alt="icono"></img></Grid>
                    <Grid xs={9} sm={9} md={9} className={classes.listFeaturesItem} ><Typography  className={classes.ListFeaturesText}>Tu {this.mainWordPhone()} puede ser nuevo o usado e igual lo puede asegurar.</Typography></Grid>
                  </Grid>
  
                </div>
                
                <Typography className={classes.subTituloPrincipal} style={{textAlign:"center"}}>
                Cuéntanos sobre tu {this.mainWordPhone()}
                  </Typography>
                  <Typography className={classes.subTituloPrincipalText} style={{textAlign:"center"}}>
                  Cotiza en menos de 3 minutos, estas en el paso 1 de 2
                  </Typography>


                  {/* <div style={{ marginBottom: "1rem", marginTop: "2rem" }}>
                    &nbsp;
                  </div>
                  <Typography className={classes.tituloPrincipal} style={{ color: this.currentMainColor() }}>
                    Obtén en simples pasos el seguro para tu teléfono móvil
                  </Typography> */}
                  {/* <Typography className={classes.labelSelect}>IMEI</Typography> */}
                  <FormControl
                    style={{
                      marginBottom: "1rem",
                      alignSelf: "stretch",
                      maxWidth: "450px",
                    }}
                    variant="outlined"
                    className={classes.homeInputForm}
                  >
                    <Grid
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Grid item>
                        <Tooltip title="¿Cómo lo obtengo?">
                          <IconButton
                            onClick={() => this.handleClickOpen()}
                            aria-label="¿Cómo lo obtengo?"
                          >
                            <HelpIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      <Grid style={{ flexGrow: "1", cursor: "pointer" }}>
                        <TextField
                          style={{ display: "flex" }}
                          label="¿Cuál es el IMEI de tu equipo?                          "
                          defaultValue={this.state["imei"]}
                          onChange={(e) =>
                            this.handleChangeTextField(e, "imei")
                          }
                          variant="outlined"
                          onKeyDown={(e) =>
                            (e.key == "," || e.key == ".") && e.preventDefault()
                          }
                        />
                      </Grid>
                    </Grid>
                  </FormControl>

                  <FormControl
                    className={classes.homeInputForm}
                    style={{
                      marginBottom: "2rem",
                      alignSelf: "stretch",
                      maxWidth: "450px",
                    }}
                    variant="outlined"
                  >
                    <TextField
                      label="Correo de Contacto"
                      defaultValue={this.state["Email"]}
                      onChange={(e) =>
                        this.handleChangeTextField(e, "Email")
                      }
                      variant="outlined"
                      type="email"
                    />
                  </FormControl>

                  <Button
                    onClick={() => this.toSecondTab()}
                    color="primary"
                    className={classes.homeButtonForm}
                    variant="contained"
                    disabled={!this.state.imei || !this.state.introFormValid}
                    style={{
                      backgroundColor: this.currentMainColor()

                    }}
                  >
                    Iniciar cotización                  </Button>
                </div>
              </Zoom>
            )}
            {this.state.activeTab === 2 && (
              <Zoom in={true} timeout={400}>
                <div className={classes.containerTab}>

                <Grid className={classes.progessBarTop} container>
                <Grid xs={6} sm={6} md={6}  ></Grid>
                <Grid xs={6} sm={6} md={6} className={classes.activeStep} ></Grid>
                </Grid>

                  <Button
                    onClick={() => this.toFirstTab()}
                    style={{
                      marginBottom: "1rem",
                      marginTop: "1rem",
                      color: "#878787",
                      fontFamily: "Roboto",
                    }}
                  >
                    <ArrowBack></ArrowBack> Paso 2 de 2 de la cotización
                  </Button>


                  <Typography className={classes.tituloPrincipal}>
                  Este plan <br></br> tenemos para ti

                  </Typography>
                  
                  {/* <Typography className={classes.tituloPrincipal} style={{ color: this.currentMainColor() }}>
                    Encontramos{" "}
                    {this.state.planesEncontrados.length == 1
                      ? "un"
                      : this.state.planesEncontrados.length}{" "}
                    seguro
                    {this.state.planesEncontrados.length === 1 ? "" : "s"} para
                    tu teléfono móvil:
                  </Typography> */}


                  <>

                  
                  <div className={classes.conditionFeaturesList}>
                    <img  src={iconoFeaturesConditionShield} className={classes.conditionFeatureIconShield} alt="icono" style={{marginLeft:"20px"}}></img>
                    <img  src={iconoFeaturesConditionShield} className={classes.conditionFeatureIconShield} alt="icono"></img>
                    <img  src={iconoFeaturesConditionShield} className={classes.conditionFeatureIconShield} alt="icono"></img>
                    
                      <div className={`${classes.conditionFeatures}`}>
                        <img  src={iconoFeaturesCondition} className={classes.conditionFeatureIcon} alt="icono"></img>
                         <div className={classes.conditionFeatureText}> Cobertura de robo y daño</div>
                      </div>
                      <div className={`${classes.conditionFeatures}`}>
                        <img  src={iconoFeaturesCondition} className={classes.conditionFeatureIcon} alt="icono"></img>
                         <div className={classes.conditionFeatureText}> ¿Te encanta tu {this.mainWordPhone()}? Ahora quedará protegido</div>
                      </div>
                      <div className={`${classes.conditionFeatures}`}>
                        <img  src={iconoFeaturesCondition} className={classes.conditionFeatureIcon} alt="icono"></img>
                         <div className={classes.conditionFeatureText}> En caso de robo deducible del 25%
                         <small style={{fontWeight: "normal"}}> Ejemplo: $1,000.000 fue el costo de tu {this.mainWordPhone()}. El seguro pagará $750.000.  </small></div>
                      </div>
                      <div className={`${classes.conditionFeatures}`}>
                        <img  src={iconoFeaturesCondition} className={classes.conditionFeatureIcon} alt="icono"></img>
                         <div className={classes.conditionFeatureText}> En caso de daño el deducible del 15%</div>
                      </div>
                  
                      <div className={classes.conditionFeatureText} style={{color:"#116cc1", textAlign:"center", marginBottom: "10px", marginTop: "0"}}> Este es el valor del seguro para tu equipo.</div>
                      
                      <div style={{width: "100%"}}>
                    {this.state.planesEncontrados.map((e, i) => (
                      <div
                        onClick={() => this.seleccionarPlan(e)}
                        key={i}
                        className={`${classes.tarjetaPlan} ${
                          e.isActive ? "plan-active" : ""
                        }`}
                        onMouseEnter={this.handleMouseEnterCard} // Or onMouseOver
                        onMouseLeave={this.handleMouseLeaveCard}
                        style={{ borderColor: this.currentMainColor(), backgroundColor: this.state.hoverCard || e.isActive ? this.currentMainColor() : "white" }}
                      >
                        {/* <Typography
                          style={{ marginBottom: "25px", display: "none" }}
                          className={classes.tituloPlan}
                        >
                          {e.name}{" "}
                          {e.premiumFrequency == "Monthly"
                            ? "Mensual"
                            : e.premiumFrequency == "Annually"
                            ? "Anual"
                            : ""}
                        </Typography>
                        <h2 className={classes.titleH2Plan}>Coberturas:</h2> */}

                        <Typography
                          style={{ marginBottom: "10px" }}
                          className={classes.tituloPlan}
                        >
                          
                          {e.coverages.map((coverage, index) => (
                            <span key={coverage}>
                              {coverage.name} {index == (e.coverages.length - 1) ? "" : "y "}
                            </span>
                          ))}
                        </Typography>
                        
                        {/* <Typography className={classes.textoPlan}>
                          {e.coverages.map((coverage) => (
                            <span key={coverage}>
                              <span className={classes.titleH3Plan} style={{ marginBottom: "0" }}>{coverage.name}</span>
                              <span style={{ marginTop: "0", marginBottom: "0" }}>{coverage.description}</span>
                              <span style={{ marginTop: "0" }}>Deducible: {coverage.deductibleValue} UF</span>
                            </span>
                          ))}
                        </Typography> */}
                        <Typography className={classes.precioPlan} style={{ marginBottom: "0", color: this.currentMainColor() }} >
                          ${parseFloat(e.pricePesos).toLocaleString("es")} <span style={{ fontSize: "14px" }}>mensual</span>
                        </Typography>
                        {/* <Typography className={classes.precioPlanRef} style={{color: this.currentMainColor()}}>
                          ({parseFloat(e.price).toLocaleString("de-DE")} UF)  
                          <small style={{ fontSize: "12px", fontStyle: 400, marginLeft: "5px" }}>(Referencia ${parseFloat(e.valueUf).toLocaleString("es")} al {e.dateUf})</small>
                        </Typography> */}
                      
                        
                      </div>
                    ))}
                    </div>
                    </div>
                  </>
                  <Button
                    ref={this.myRef}
                    onClick={() => this.toForthTab()}
                    disabled={!this.state.planSeleccionado}
                    color="primary"
                    style={{
                      fontFamily: "Source Sans Pro",
                      fontWeight: "bold",
                      fontSize: "24px",
                      textTransform: "capitalize",
                      marginBottom: "2rem",
                      alignSelf: "stretch",
                      maxWidth: "450px",
                      padding: "1rem 0rem",
                      backgroundColor: this.currentMainColor()

                    }}
                    variant="contained"
                    
                  >
                    Activar mi protección
                  </Button>
                </div>
              </Zoom>
            )}
            {this.state.activeTab === 3 && (
              <Zoom in={true} timeout={400}>
                <div className={classes.containerTab}>
                  <Button
                    onClick={() => this.toSecondTab()}
                    style={{
                      marginBottom: "1rem",
                      marginTop: "2rem",
                      color: "#878787",
                      fontFamily: "Roboto",
                    }}
                  >
                    <ArrowBack></ArrowBack> Volver atrás
                  </Button>
                  <Typography className={classes.tituloPrincipal} style={{ display: "none"}}>
                    {this.state.planSeleccionado.name}
                  </Typography>
                  <Typography className={classes.textoPlanDetalle}>
                    <h2>Coberturas:</h2>
                    {this.state.planSeleccionado.coverages.map((coverage) => (
                      <div>
                        <h3  style={{ marginBottom: "0" }}>{coverage.name}</h3>
                        <p style={{ marginTop: "0", marginBottom: "0" }}>{coverage.description}</p>
                        <p style={{ marginTop: "0" }}>Deducible: {coverage.deductibleValue} UF</p>
                      </div>
                    ))}
                  
                    <h3 style={{ margin: 0 }} >Beneficios:</h3>
                    {this.state.planSeleccionado.benefits.length &&
                      this.state.planSeleccionado.benefits.map((benefit) => (
                        <div>
                          <p style={{ marginTop: "0" }}>Además al proteger tu equipo te entregamos el siguiente Beneficio Mcafee que te protege contra robos, mejora tu privacidad y seguridad al tener antivirus y potencia el rendimiento de tu equipo.</p>
                          {/* <h6 style={{ margin: 0 }} >{benefit.title}</h6>
                          <small style={{ margin: 0 }} >{benefit.description}</small> */}
                        </div>
                      ))}
                  </Typography>
                  <Typography style={{marginBottom: "40px", fontWeight: '600', marginTop: '-15px', fontStyle: 'italic'}}>
                  <small >Seguro cubre dos eventos al año.</small>
                  </Typography>
                  <Typography className={classes.precioPlanDetalle} style={{color: this.currentMainColor()}}>
                          Valor: ${parseFloat(this.state.planSeleccionado.pricePesos).toLocaleString("es")} <span style={{ fontSize: "18px" }}>mensual</span>
                        </Typography>
                        <Typography className={classes.precioPlanRef} style={{marginBottom: "25px", color: this.currentMainColor()}}>
                          ({parseFloat(this.state.planSeleccionado.price).toLocaleString("de-DE")} UF)
                          <small style={{ fontSize: "12px", fontStyle: 400, marginLeft: "5px" }}>(Referencia ${parseFloat(this.state.planSeleccionado.valueUf).toLocaleString("es")} al {this.state.planSeleccionado.dateUf})</small>
                        </Typography>
                  <Button
                    onClick={() => this.toForthTab()}
                    disabled={!this.state.planSeleccionado}
                    color="primary"
                    style={{
                      fontFamily: "Source Sans Pro",
                      fontWeight: "bold",
                      fontSize: "24px",
                      textTransform: "capitalize",
                      marginBottom: "2rem",
                      alignSelf: "stretch",
                      maxWidth: "450px",
                      padding: "1rem 0rem",
                      backgroundColor: this.currentMainColor()
                    }}
                    variant="contained"
                  >
                    Seleccionar
                  </Button>
                </div>
              </Zoom>
            )}
            {this.state.activeTab === 4 && (
              <Zoom in={true} timeout={400}>
                <form onSubmit={(e) => this.handleSubmit(e)}>
                  <div className={classes.containerTab}>

                  <Grid className={classes.progessBarTop} container>
                  <Grid xs={6} sm={6} md={6} className={classes.activeStep}></Grid>
                  <Grid xs={6} sm={6} md={6}  ></Grid>
                  </Grid>
                  
                    <Button
                      onClick={() => this.toSecondTab()}
                      style={{
                        marginBottom: "1rem",
                        marginTop: "0rem",
                        color: "#878787",
                        fontFamily: "Roboto",
                      }}
                    >
                      <ArrowBack></ArrowBack> Estás en el paso 1 de 2 de la contratación
                    </Button>

              
                    <Typography className={classes.tituloPrincipal}>
                    Completa tu protección con estos datos
                    </Typography>
                    <Grid container className={classes.checkoutStep}>
                        <Grid item xs={2} sm={2} md={2}>
                          <img
                            src={require("../images/icons/002-lock.png")}
                            className={classes.checkoutStepIcon}
                            alt="icono"
                          ></img>
                      </Grid>
                      <Grid item xs={10} sm={10} md={10}>
                        <Typography className={classes.checkoutStepText}>
                          Datos Personales
                        </Typography>
                      </Grid>
                    </Grid>

                    
                    <Grid container spacing={0}>
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingRight: "1rem",
                          marginBottom: "1rem",
                        }}
                        item
                        xs={6}
                        sm={6}
                        md={6}
                      >
                        <TextField
                          defaultValue={this.state["RUT"]}
                          onChange={(e) => this.handleChangeTextField(e, "RUT")}
                          label="RUT (sin puntos y con guión)"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingRight: "1rem",
                          marginBottom: "1rem",
                        }}
                        item
                        xs={6}
                        sm={6}
                        md={6}
                      >
                        <TextField
                          defaultValue={this.state["Nombres"]}
                          onChange={(e) =>
                            this.handleChangeTextField(e, "Nombres")
                          }
                          label="Nombres"
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingRight: "1rem",
                          marginBottom: "1rem",
                        }}
                        item
                        xs={6}
                        sm={6}
                        md={6}
                      >
                        <TextField
                          defaultValue={this.state["Apellido Paterno"]}
                          onChange={(e) =>
                            this.handleChangeTextField(e, "Apellido Paterno")
                          }
                          label="Apellido Paterno"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingRight: "1rem",
                          marginBottom: "1rem",
                        }}
                        item
                        xs={6}
                        sm={6}
                        md={6}
                      >
                        <TextField
                          defaultValue={this.state["Apellido Materno"]}
                          onChange={(e) =>
                            this.handleChangeTextField(e, "Apellido Materno")
                          }
                          label="Apellido Materno"
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingRight: "1rem",
                          marginBottom: "1rem",
                        }}
                        item
                        xs={6}
                        sm={6}
                        md={6}
                      >
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          type="date"
                          defaultValue={this.state["Fecha de Nacimiento"]}
                          onChange={(e) =>
                            this.handleChangeTextField(e, "Fecha de Nacimiento")
                          }
                          label="Fecha de Nacimiento"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingRight: "1rem",
                          marginBottom: "1rem",
                        }}
                        item
                        xs={6}
                        sm={6}
                        md={6}
                      >
                        <TextField
                          defaultValue={this.state["Dirección"]}
                          onChange={(e) =>
                            this.handleChangeTextField(e, "Dirección")
                          }
                          label="Dirección"
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingRight: "1rem",
                          marginBottom: "1rem",
                        }}
                        item
                        xs={6}
                        sm={6}
                        md={6}
                      >
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <InputLabel id="select-region-label">
                            Región
                          </InputLabel>
                          <Select
                            label="Región"
                            id="select-region"
                            value={this.state["Región"]}
                            onChange={(e) => this.handleChangeRegion(e)}
                          >
                            {this.state.regionesList.map((e) => (
                              <MenuItem key={e} value={e}>
                                {e}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingRight: "1rem",
                          marginBottom: "1rem",
                        }}
                        item
                        xs={6}
                        sm={6}
                        md={6}
                      >
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <InputLabel id="select-comuna-label">
                            Comuna
                          </InputLabel>
                          <Select
                            label="Comuna"
                            id="select-comuna"
                            value={this.state["Comuna"]}
                            onChange={(e) =>
                              this.handleChangeTextField(e, "Comuna")
                            }
                          >
                            {this.state.comunasList.map((e) => (
                              <MenuItem key={e} value={e}>
                                {e}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid container className={classes.checkoutStep}>
                        <Grid item xs={2} sm={2} md={2}>
                          <img
                            src={require("../images/icons/008-user.png")}
                            className={classes.checkoutStepIcon}
                            alt="icono"
                          ></img>
                      </Grid>
                      <Grid item xs={10} sm={10} md={10}>
                        <Typography className={classes.checkoutStepText}>
                          Datos de Contacto
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container spacing={0}>
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingRight: "1rem",
                          marginBottom: "1rem",
                        }}
                        item
                        xs={6}
                        sm={6}
                        md={6}
                      >
                        <TextField
                          defaultValue={this.state["Teléfono"]}
                          onChange={(e) =>
                            this.handleChangeTextField(e, "Teléfono")
                          }
                          label="Teléfono"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingRight: "1rem",
                          marginBottom: "1rem",
                        }}
                        item
                        xs={6}
                        sm={6}
                        md={6}
                      >
                        <TextField
                          defaultValue={this.state["Email"]}
                          onChange={(e) =>
                            this.handleChangeTextField(e, "Email")
                          }
                          label="Email"
                          type="email"
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      style={{ flexWrap: "nowrap", paddingRight: "2rem" }}
                      container
                      spacing={0}
                    >
                      <Grid
                        item
                        style={{ marginBottom: "1rem", marginRight: "10px" }}
                      >
                        <Checkbox
                          checked={this.state.condicion1}
                          onChange={(e) =>
                            this.handleChangeCheckbox(e, "condicion1")
                          }
                          color="primary"
                          inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                      </Grid>
                      <Grid
                        item
                        style={{ paddingTop: "10px", marginBottom: "1rem" }}
                      >
                        <Link
                          style={{
                            textDecoration: "underline",
                            fontFamily: "Source Sans Pro",
                            fontWeight: "400",
                            fontSize: "14px",
                            color: this.currentMainColor(),
                          }}
                          href={pdfTerminosCondiciones}
                          target="_blank"
                        >
                          He leído y comprendo las condiciones generales y
                          particulares{" "}
                        </Link>

                        <p style={{ fontSize:"12px" }}>Autorizo que el cobro de la prima de este seguro se realice en la tarjeta de crédito indicada y que las comunicaciones relacionadas al seguro se envíen al correo electrónico informado. En caso de desconocerse el correo electrónico o no ser efectivo el envío, autorizo a que las comunicaciones me sean enviadas al correo electrónico que he informado al contratante de la póliza colectiva
Autorizo a Chubb Seguros Chile S.A. para que pueda efectuar el tratamiento y almacenamiento de mis datos personales proporcionados en esta contratación del Seguro y puedan ser utilizados en la administración, análisis, cumplimiento y/o auditoría del mismo.
Declaro que acepto voluntariamente la contratación de este seguro</p>

                      </Grid>
                    </Grid>

                    {/* <Grid
                      style={{ flexWrap: "nowrap", paddingRight: "2rem" }}
                      container
                      spacing={0}
                    >
                      <Grid
                        item
                        style={{ marginBottom: "1rem", marginRight: "10px" }}
                      >
                        <Checkbox
                          checked={this.state.condicion3}
                          onChange={(e) =>
                            this.handleChangeCheckbox(e, "condicion3")
                          }
                          color="primary"
                          inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                      </Grid>
                      <Grid
                        item
                        style={{
                          paddingTop: "10px",
                          marginBottom: "1rem",
                          color: "#878787",
                          fontFamily: "Source Sans Pro",
                          fontWeight: "400",
                          fontSize: "14px",
                        }}
                      >
                        Autorizo a Chubb Seguros Chile S.A. para que pueda
                        efectuar el tratamiento y almacenamiento de mis datos
                        personales proporcionados en esta contratación del
                        Seguro y puedan ser utilizados en la administración,
                        análisis, cumplimiento y/o auditoría del mismo.
                      </Grid>
                    </Grid>

                    <Grid
                      style={{ flexWrap: "nowrap", paddingRight: "2rem" }}
                      container
                      spacing={0}
                    >
                      <Grid
                        item
                        style={{ marginBottom: "1rem", marginRight: "10px" }}
                      >
                        <Checkbox
                          checked={this.state.condicion4}
                          onChange={(e) =>
                            this.handleChangeCheckbox(e, "condicion4")
                          }
                          color="primary"
                          inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                      </Grid>
                      <Grid
                        item
                        style={{
                          paddingTop: "10px",
                          marginBottom: "1rem",
                          color: "#878787",
                          fontFamily: "Source Sans Pro",
                          fontWeight: "400",
                          fontSize: "14px",
                        }}
                      >
                        Declaro que acepto voluntariamente la contratación de
                        este seguro.
                      </Grid>
                    </Grid> */}
                    <Button
                      type="submit"
                      disabled={!formValid}
                      color="primary"
                      style={{
                        display: "none",
                        fontFamily: "Source Sans Pro",
                        fontWeight: "bold",
                        fontSize: "24px",
                        textTransform: "capitalize",
                        marginBottom: "2rem",
                        marginTop: "2rem",
                        alignSelf: "stretch",
                        maxWidth: "450px",
                        padding: "1rem 0rem",
                      }}
                      variant="contained"
                    >
                      Continuar
                    </Button>
                    <Button
                      disabled={this.state.isLoading || !formValid}
                      onClick={() => this.handleClickBotonPagar()}
                      type="button"
                      color="primary"
                      style={{
                        fontFamily: "Source Sans Pro",
                        fontWeight: "bold",
                        fontSize: "24px",
                        textTransform: "capitalize",
                        marginBottom: "2rem",
                        marginTop: "2rem",
                        alignSelf: "stretch",
                        maxWidth: "450px",
                        padding: "1rem 0rem",
                        backgroundColor: this.currentMainColor()
                      }}
                      variant="contained"
                    >
                      Comprar
                    </Button>
                  </div>
                </form>
              </Zoom>
            )}
          </Grid>
          <Grid className={classes.fondoImagen} item xs={12} sm={12} md={6} style={{backgroundImage: `url(${this.mainBackgroundImage()})`}}>
            <img
              className={classes.iconoCentral}
              src={this.currentIconoCentral()}
              alt="icono"
            ></img>
          </Grid>
        </Grid>
        <FooterGarnet />

        {this.state.mensajesError.map((e, i) => (
          <Snackbar
            style={{ marginBottom: String(55 * i) + "px" }}
            key={i}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={e.active}
            autoHideDuration={6000}
            variant="error"
            message={e.message}
            action={
              <React.Fragment>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={() => this.handleCloseSnackbar(i)}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
        ))}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  preference_id: state.preference_id,
});
export default connect(mapStateToProps)(
  withRouter(withStyles(styles)(Landing1))
);
