import React, { Component } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Topbar from "../components/TopbarGarnet";
import FooterGarnet from "../components/FooterGarnet";
import Typography from "@material-ui/core/Typography";
import API from "../api/axios";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import HelpIcon from "@material-ui/icons/Help";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";

const fondoImagenUrl = require("../images/productos_apple.png");
const iconoCentral = require("../images/icono_central.png");

const styles = (theme) => ({
  tituloPrincipal1: {
    fontFamily: "Source Sans Pro",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "48px",
    lineHeight: "53px",
    color: "#0087C2",
    marginBottom: "1rem",
  },
  tituloPrincipal2: {
    fontFamily: "Source Sans Pro",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "32px",
    lineHeight: "53px",
    color: "#0087C2",
    marginBottom: "3rem",
  },
  textoDetalle: {
    fontFamily: "Source Sans Pro",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "24px",
    lineHeight: "26px",
    color: "#474747",
    marginBottom: "1.5rem",
  },
  iconoCentral: {
    width: "200px",
    marginLeft: "-100px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  fondoImagen: {
    background: `url(${fondoImagenUrl}) no-repeat`,
    backgroundSize: "cover",
    height: "960px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      height: "100vh",
      display: "block",
      position: "fixed",
      top: 0,
      width: "100%",
      backgroundPosition: "center",
    },
  },
  containerTab: {
    padding: "10px 7rem 10px 5rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 1rem 10px 1rem",
      height: "initial",
      overflow: "initial",
      overflowX: "initial",
      backgroundColor: "rgba(255,255,255,1)",
      position: "relative",
      zIndex: "1",
      minHeight: "calc(100vh - 160px)",
    },
  },
});
const queryString = require("query-string");
class LandingExito extends Component {
  constructor(props) {
    super(props);
    this.state = {
      plan: "",
      openSnackbar: false,
      mensajesError: [],
      currentPath: window.location.hash.split('/')[1],
    };
  }
  toRetry() {}
  componentDidMount() {
    console.log(window.location.hash);
    var values = queryString.parse(window.location.hash);
    console.log(values);

    // Temporal code
    // if (!values.purchase){
    //   var newUrl = window.location.hash.replaceAll("&#x3D;", "=").replaceAll("&amp;", "&");
    //   values = queryString.parse(newUrl);
    //   console.log(values);
    // }
    // if (values.purchase) {
    //   API({
    //     url: "api/landing/mtek/confirm",
    //     method: "POST",
    //     data: { purchase: values.purchase },
    //   })
    //     .then((response) => {})
    //     .catch((e) => {
    //       console.log(e);
    //       const mensajesError = [
    //         {
    //           active: true,
    //           message:
    //             "Lo sentimos, tenemos un error interno. Intente de nuevo mas tarde.",
    //         },
    //       ];
    //       this.setState({ mensajesError });
    //     });
    // } else {
    //   // TO-DO: Loggear que no viene el purchase y salvar todo dato posible
    // }
  }
  handleCloseSnackbar(index) {
    let mensajesError = this.state.mensajesError.map((e, i) => {
      if (i == index) {
        return { ...e, active: false };
      } else {
        return e;
      }
    });
    this.setState({ mensajesError: mensajesError });
  }
  currentMainColor(){
    if (this.state.currentPath == "virgin") {
      return "#EE0001";
      
    }
    else if (this.state.currentPath == "digitek") {
        return "#444444";
        
    }
    else if (this.props.currentPath == "losheroes") {
        return "#3d64a3";
        
    }
    else if (this.props.currentPath == "h2o") {
      return "#15669D";  
    }
    else if (this.props.currentPath == "maconline") {
        return "#15669D";   
    }
    else if (this.props.currentPath == "iphone") {
      return "#15669D";   
  }
    else {
      return "#15669D";
    }
}
  currentIconoCentral(){
    if (this.state.currentPath == "virgin") {
      return require("../images/icono_central_virgin.png");
      
    }
    else if (this.state.currentPath == "digitek") {
        return require("../images/icono_central_digitek.png");
        
    }
    else if (this.state.currentPath == "losheroes") {
        return iconoCentral;
        
    }
    else if (this.state.currentPath == "h2o") {
        return iconoCentral;
        
    }
    else if (this.props.currentPath == "maconline") {
        return iconoCentral;
    }
    else if (this.props.currentPath == "iphone") {
      return iconoCentral;
  }
    else {
      return iconoCentral;
    }
}
mainWordPhone(){
  if (this.state.currentPath == "iphone") {
    return 'iPhone';
  }
  else if (this.state.currentPath == "maconline") {
    return 'iPhone';
  }
  else {
    return 'Smartphone';
  }
}

mainBackgroundImage(){
  if (this.state.currentPath == "iphone") {
    return require("../images/productos_iphone.png");
  }
  else if (this.state.currentPath == "maconline") {
    return require("../images/productos_iphone.png");
  }
  else {
    return fondoImagenUrl;
  }
}
  render() {
    const { classes } = this.props;
    return (
      <>
      <Topbar currentPath = {this.state.currentPath} />
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={6}>
            <div className={classes.containerTab}>
              <Typography
                style={{ marginTop: "2rem",  color: this.currentMainColor() }}
                className={classes.tituloPrincipal1}
              >
                Compra exitosa!
              </Typography>
              <Typography className={classes.tituloPrincipal2} style={{lineHeight:1,  color: this.currentMainColor()}}>
                Ya tienes tu {this.mainWordPhone()} móvil asegurado.
              </Typography>
              <Typography className={classes.textoDetalle}>
                Recibirás en tu correo una copia de póliza de seguro.
                <br></br>
              </Typography>

              <Typography className={classes.textoDetalle} style={{fontSize:"18px"}}>
                <p>Cualquier duda o consulta puedes contactarnos a:<br></br>Correo: <a href="mailto:contacto@grupogarnet.com">contacto@grupogarnet.com</a><br></br>
                Whatsapp: <a href="https://api.whatsapp.com/send?phone=56935390298">+569 3539 0298</a>  /  <a href="https://api.whatsapp.com/send?phone=56932233326">+562 3223 3326</a></p>
              </Typography>
            </div>
          </Grid>
          <Grid className={classes.fondoImagen} item xs={12} sm={12} md={6} style={{backgroundImage: `url(${this.mainBackgroundImage()})`}}>
            <img
              className={classes.iconoCentral}
              src={this.currentIconoCentral()}
              alt="icono"
            ></img>
          </Grid>
        </Grid>
        <FooterGarnet />
        {this.state.mensajesError.map((e, i) => (
          <Snackbar
            style={{ marginBottom: String(55 * i) + "px" }}
            key={i}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={e.active}
            autoHideDuration={6000}
            variant="error"
            message={e.message}
            action={
              <React.Fragment>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={() => this.handleCloseSnackbar(i)}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
        ))}
      </>
    );
  }
}

export default withRouter(withStyles(styles)(LandingExito));
